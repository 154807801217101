import React from 'react'

const Faq = () => {
  return (
    <div className='max-w-[1200px] mx-auto px-6 mb-16'>
        <h3 className='mb-4 text-2xl'>Frequently Asked Questions</h3>

        <h4 className='text-lg border-t-[1px] border-gray-300 pt-4'>Which Plan Should I Use?</h4>
        <p className='mb-4 text-gray-600'>If this is your very first website and you are unsure then the recommendation is to start with the starter package. This will give you a taste of what a website can do for your business. Includes a main page that can touch on different aspects of your business and convey that efficiently to your potential customers. In the future when you add more information and one page wont do then you can upgrade your account and have a multipage website.</p>

        <h4 className='text-lg border-t-[1px] border-gray-300 pt-4'>I Already Have A Website</h4>
        <p className='mb-4 text-gray-600'>If you have an existing website we can still find ways to improve on it. Aspects like designing a web presence with current industry standards and updating your copy writing to include seo optimized text to help your potential customers find you easily.</p>

    </div>
  )
}

export default Faq